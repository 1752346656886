class ContactUsForm {
    constructor(domElement = null) {
        this.form = $(domElement)
        if(!this.form.exists()) {
            console.error('Please provide propper DOM element for ContactUs form class!')
            return
        }

        this.isSubmitting = false
        this.pageContainer = this.form.closest('.contact-us')
        this.postRoute = this.form.attr('action')

        this.captchaPublicKey = null
        this.captchaInput = this.form.find('.captcha-input')

        this.init()
        this.initializeEventListeners()
    }

    init() {
        if(this.captchaInput.exists()) {
            this.grecaptcha = grecaptcha
            this.captchaPublicKey = this.captchaInput.data('key')

            this.initRecaptcha()
        }
    }

    initializeEventListeners() {
        this.form.on('submit', this.handleFormSubmit.bind(this))
        this.pageContainer.on('click', '.back-to-contact', this.handleBackToFormButtonClick.bind(this))
    }

    initRecaptcha() {
        this.grecaptcha.ready(() => {
            this.executeRecaptcha()
        })
    }

    executeRecaptcha() {
        this.grecaptcha.execute(this.captchaPublicKey, { action: 'contact_form' }).then((token) => {
            this.captchaInput.val(token)
        })

        // Token is alive for 120sec, so we will refresh it
        setTimeout(() => {
            this.executeRecaptcha()
        }, 110000)
    }

    handleSuccessResponse(responseData = {}) {
        // Toggle content
        this.pageContainer.addClass('submit-success')

        // Scroll to top/message
        this.scrollTo(this.pageContainer)
    }

    clearFormErrors() {
        const formGroups = this.form.find('.form-group')
        formGroups.each(function() {
            const fieldError = $(this).find('.form-error')
            fieldError.html('')
        })
    }

    handleFormErrors({ errors }) {
        if (!errors) return

        // CSRF
        if(errors.hasOwnProperty('csrf_token')) {
            console.error('CSRF Error')

            location.reload()

            return
        }

        let scrollToField = null

        Object.keys(errors).forEach(fieldName => {
            const inputSelector = `input[name="${fieldName}"], select[name="${fieldName}"], textarea[name="${fieldName}"], div[data-group-name="${fieldName}"]`
            const $input = this.form.find(inputSelector)

            if ($input.exists()) {
                const fieldErrors = errors[fieldName]
                const errorsMarkup = `
                    <ul>
                        ${fieldErrors.map(error => `<li>${error}<li>`).join(' ')}
                    </ul>
                `

                const formGroup = $input.closest('.form-group')
                const fieldError = formGroup.find('.form-error')

                fieldError.html(errorsMarkup)

                if(!scrollToField) {
                    scrollToField = formGroup
                }
            }
        })

        if(scrollToField) {
            this.scrollTo(scrollToField)
        }
    }

    createPayload() {
        const formData = new FormData()

        const serializedData = this.form.serializeArray()
        serializedData.forEach(data => {
            const { name, value } = data

            formData.append(name, value)
        })

        return formData
    }

    /**
     * Scroll to helper function
     * @param {jQuery selector} $target
     */
    scrollTo($target) {
        const header = $('.header')
        const targetOffset = $target.offset().top - header.height() - 15

        $('html, body').animate({
            'scrollTop': targetOffset
        }, 1300)
    }

    handleBackToFormButtonClick() {
        // Just reload page -> Clean form without success messages
        location.reload()
    }

    async handleFormSubmit(event) {
        event.preventDefault()

        if (this.isSubmitting) {
            return
        }

        this.isSubmitting = true

        // Reset form errors
        this.clearFormErrors()

        try {
            const formData = this.createPayload()
            const response = await axios.post(this.postRoute, formData)

            this.handleSuccessResponse(response)

            this.isSubmitting = false

        } catch(err) {
            if(err.response.status == 400) {
                const { data } = err.response.data

                this.handleFormErrors(data)
            }

            this.isSubmitting = false

            throw err
        }

    }
}

const forms = $('.contact-us__form .form--contact')
forms.each(function() {
    new ContactUsForm(this)
})
