class StackTable {
    constructor(domNode = null) {
        this.container = $(domNode)

        this.isEditmode = this.container.hasClass('editmode')

        this.setHeights()
        this.initializeEventListeners()
    }

    setHeights() {
        // Don't euqalize headings in editmode, this will break block controls
        if(this.isEditmode) {
            return
        }

        let maxHeight = 0

        const stackTitles = this.container.find('.stack__title')
        stackTitles.css('height', '')

        stackTitles.each((index, item) => {
            const itemHeight = $(item).height()

            if (itemHeight > maxHeight) {
                maxHeight = itemHeight
            }
        })

        stackTitles.css('height', maxHeight)
    }

    initializeEventListeners() {
        $(window).on('resize', () => {
            this.setHeights()
        })
    }
}

const stackTables = $('.stack-table')
stackTables.each(function() {
    new StackTable(this)
})