/**
 * Expose some of packages globally
 */
import $ from 'jquery'
import axios from 'axios'
import slick from 'slick-carousel'
import select2 from 'select2'

window.jQuery = window.$ = $
window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.slick = slick
window.select2 = select2

/**
 * Helpers & usefull pollyfills
 */

// Check wheter selected element exists in current DOM
$.fn.exists = function () {
    return this.length !== 0
}

/**
 * https://stackoverflow.com/questions/31221341/ie-does-not-support-includes-method
 */
if (!Array.prototype.includes) {
    Array.prototype.includes = function(search, start) {
      'use strict'

        if (typeof start !== 'number') {
            start = 0
        }

        if (start + search.length > this.length) {
            return false
        } else {
            return this.indexOf(search, start) !== -1
        }
    }
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray
 */
if (!Array.isArray) {
    Array.isArray = function(arg) {
        return Object.prototype.toString.call(arg) === '[object Array]'
    }
}
