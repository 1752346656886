/**
 * Homepage slider
 */
class HomeSlider {
    constructor(domElement = null) {
        this.sliderContainer = $(domElement)
        if(!this.sliderContainer.exists()) {
            console.error('Please provide proper DOM element for Homepage slider class')
            return
        }

        this.isEditmode = this.sliderContainer.data('editMode') == true
        this.showArrows = this.sliderContainer.data('showArrows') == true

        this.init()
    }

    init() {
        const sliderOptions = this.getSliderOptions()
        this.sliderContainer.slick(sliderOptions)
    }

    getSliderOptions() {
        let options = {
            arrows: false,
            draggable: true,
            fade: true,
            speed: 860,
            infinite: true,
            cssEase: 'ease-in-out',
            pauseOnHover: false,
            pauseOnFocus: false,
        }

        if (this.showArrows) {
            options = {
                ...options,
                pauseOnHover: true,
                pauseOnFocus: true,
                arrows: true,
                prevArrow: '<button class="slick-prev"><span class="material-icons">arrow_back</span></button>',
                nextArrow: '<button class="slick-next"><span class="material-icons">arrow_forward</span></button>',
            }
        }

        if (!this.isEditmode) {
            options = {
                ...options,
                autoplay: true,
                autoplaySpeed: 2500,
            }
        } else {
            options = {
                ...options,
                arrows: true,
                prevArrow: '<button class="slick-prev"><span class="material-icons">arrow_back</span></button>',
                nextArrow: '<button class="slick-next"><span class="material-icons">arrow_forward</span></button>',
            }
        }

        return options
    }
}

const sliders = $('.home__slider')
sliders.each(function() {
    new HomeSlider(this)
})
