class Careers {
    constructor(domNode = null) {
        this.mainContainer = $(domNode)
        if(!this.mainContainer.exists()) {
            console.error('Please provide propper DOM element for Careers container!')
            return
        }

        this.talentlyftContainer = this.mainContainer.find('.talentlyft__inner')
        this.talentlyftWidgetContainer = this.talentlyftContainer.find('.talentlyft').first()

        this.openJobListing = null
        this.openJobListingContainer = this.mainContainer.find('.talentlyft__open-position-container')
        this.openJobListingButton = this.openJobListingContainer.find('.open-job-button')

        this.init()
        this.initializeEventListeners()
    }

    initializeEventListeners() {
        this.mainContainer.on('click touch', '.tl-jobs .tl-job', this.hideOtherPageContent.bind(this))
        this.mainContainer.on('click touch', '.job-description-container .tl-back', this.showOtherPageContent.bind(this))
        this.mainContainer.on('click touch', '.job-description-container .btnApply', this.showApplyForm.bind(this))

        this.mainContainer.on('click touch', '.talentlyft__open-position-container .open-job-button', this.showOpenJobApplication.bind(this))
    }

    init() {
        if(!this.talentlyftWidgetContainer.exists()) {
            return
        }

        this.observer = new MutationObserver(() => {
            const jobsList = this.mainContainer.find('ul.tl-jobs')
            if(jobsList.exists()) {
                // Don't observe anymore
                this.observer.disconnect()

                // Rendered
                this.setPositions()

                // Jobs list is rendered, but not visible
                // => Details are rendered on-load
                if(!jobsList.is(':visible')) {
                    this.hideOtherPageContent()
                }
            }
        })

        this.observer.observe(this.talentlyftWidgetContainer[0], {
            attributes: true,
            childList: true,
            characterData: true
        })
    }

    /**
     * Save & hide open job position
     * This will have separate section
     */
    setPositions() {
        this.openJobListing = null

        const positions = this.mainContainer.find('.tl-jobs .tl-job')
        positions.each((index, item) => {
            const position = $(item)

            const onclick = position.attr('onclick')
            if(onclick.includes('open-job-application')) {
                // Save & hide
                this.openJobListing = position
                this.openJobListing.addClass('hidden')

                this.openJobListingContainer.removeClass('hidden')
            }
        })
    }

    // Open position button click
    showOpenJobApplication(event) {
        event.preventDefault()

        if(this.openJobListing) {
            this.openJobListing.trigger('click')
        }
    }

    showOtherPageContent() {
        this.mainContainer.removeClass('expanded')

        const areaBricks = $('.page-content .pimcore_area_content').not('.pimcore_area_careers')
        areaBricks.removeClass('hidden')

        const descriptionContainer = this.mainContainer.find('.job-description-container')
        descriptionContainer.removeClass('apply-form-visible')

        const applicationForm = this.mainContainer.find('form.talentlyft')
        applicationForm.removeClass('visible')
    }

    hideOtherPageContent() {
        this.mainContainer.addClass('expanded')

        const areaBricks = $('.page-content .pimcore_area_content').not('.pimcore_area_careers')
        areaBricks.addClass('hidden')
    }

    showApplyForm() {
        const descriptionContainer = this.mainContainer.find('.job-description-container')
        descriptionContainer.addClass('apply-form-visible')

        const applicationForm = this.mainContainer.find('form.talentlyft')
        applicationForm.addClass('visible')
    }
}

const careerContainers = $('.talentlyft__container')
careerContainers.each(function() {
    new Careers(this)
})
