/**
 * Sticky header
 */
class StickyHeader {
    constructor(domElement = null) {
        this.headerContainer = $(domElement)
        if(!this.headerContainer.exists()) {
            console.error('Please provide proper DOM element for Header class')
            return
        }

        this.pageContainer = $('#page-container')

        this.setHeader()
        this.initializeEventListeners()
    }

    initializeEventListeners() {
        window.onscroll = () => this.setHeader()
    }

    setHeader() {
        const headerOffset = this.headerContainer[0].offsetTop
        const headerHeight = this.headerContainer.outerHeight()

        if(window.scrollY > headerOffset) {
            this.pageContainer.css({ paddingTop: headerHeight })
            this.headerContainer.addClass('fixed')
        } else {
            this.pageContainer.css({ paddingTop: 0 })
            this.headerContainer.removeClass('fixed')
        }
    }
}

const headerContainer = document.getElementById('page-header')
if ($(headerContainer).exists()) {
    new StickyHeader(headerContainer)
}
