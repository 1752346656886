/**
 * Textareas with charcter count
 */
class TextAreaWithCount {
    constructor(domElement = null) {
        this.textArea = $(domElement)
        if(!this.textArea.exists()) {
            console.error('Please provide proper Dom Element for TextArea class')
            return
        }

        this.maxLength = this.textArea.attr('maxlength')

        this.formGroup = this.textArea.closest('.form-group')
        this.counter = this.formGroup.find('.form-counter .count')

        this.setCounter()
        this.initializeEventListeners()
    }

    initializeEventListeners() {
        this.textArea.on('input paste', () => this.setCounter())
    }

    // Set counter
    // [With maxLength, browser will cancel any other actions]
    setCounter() {
        const currentLength = this.textArea.val().length
        this.counter.text(currentLength)
    }
}

// Initialize textAreas with counters
const textAreas = $('textarea.with-counter')
textAreas.each(function() {
    new TextAreaWithCount(this)
})
