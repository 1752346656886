class LanguageSwitch {
    constructor(languageSwitch = null) {
        this.languageSwitch = languageSwitch

        this.init()
        this.initializeEventListeners()
    }

    init() {
        this.languageSwitch.select2({
            minimumResultsForSearch: -1, // Remove search input
            dropdownParent: $('.header'),
        })
    }

    initializeEventListeners() {
        this.languageSwitch.on('select2:select', () => this.handleChange())
    }

    handleChange() {
        const selectedValue = this.languageSwitch.val()

        if (selectedValue) {
            window.location.href = selectedValue
        }
    }
}

const languageSwitch = $('.header .language-switch')
if (languageSwitch.exists()) {
    new LanguageSwitch(languageSwitch)
}
