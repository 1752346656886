
$(() => {
    /**
     * Animated Scroll to
     */
    $(document).on('click touch', '.scroll-to', function(event) {
        event.preventDefault()

        const hashVal = $(this).attr('href')
        const $target = $(hashVal)

        if($target.exists()) {
            const header = $('.header')
            const targetOffset = $target.offset().top - header.height() - 15

            $('html, body').animate({
                'scrollTop': targetOffset
            }, 1300)
        }
    })

})
