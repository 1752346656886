/**
 * Page Loader
 * [Hide loader overlay after DOM is ready and images are fetched + configurable timeout]
 * Configurable timeout - Since there is really no images, load will be very fast and loader won't ever be visible
 */
class PageLoader {
    constructor(domElement = null, {
        loaderDuration,
    }) {
        this.loaderContainer = $(domElement)
        if(!this.loaderContainer.exists()) {
            console.error('Please provide propper DOM element for PageLoader Class')
            return
        }

        loaderDuration = loaderDuration || 1250
        this.loaderDuration = parseInt(loaderDuration)

        this.initializeEventListeners()
    }

    initializeEventListeners() {
        window.onload = () => {
            this.hideLoader()
        }
    }

    hideLoader() {
        setTimeout(() => {
            this.loaderContainer.addClass('hidden')
        }, this.loaderDuration)
    }
}

const { loaderDuration } = window.appData
const loaderContainer = document.getElementById('page-loader')

if($(loaderContainer).exists()) {
    new PageLoader(loaderContainer, {
        loaderDuration,
    })
}
