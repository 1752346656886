class TextTeaserBlock {
    constructor(domNode = null) {
        this.container = $(domNode)
        if(!this.container.exists()) {
            console.error('Please provide propper DOM element for TextTeaserBlock Class!')
            return
        }

        this.isEditmode = this.container.hasClass('editmode')

        this.init()
    }

    init() {
        if(this.isEditmode) {
            return
        }

        let maxHeight = 0

        const teaserItems = this.container.find('.text-teaser-block__item')
        teaserItems.each((index, item) => {
            const $item = $(item)

            const $headline = $item.find('.text-teaser-block__headline')
            const headlineHeight = $headline.height()
            const descriptionHeight = $item.find('.text-teaser-block__description p').height()

            let itemHeight = headlineHeight > descriptionHeight
                ? headlineHeight * 2
                : descriptionHeight * 2

            maxHeight = itemHeight > maxHeight
                ? itemHeight
                : maxHeight
        })

        teaserItems.css('height', maxHeight)
    }
}

const teasers = $('.text-teaser-block')
teasers.each(function() {
    new TextTeaserBlock(this)
})
